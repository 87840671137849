<template>
  <UiPopup
    :model-value="modelValue"
    title="Create shadow lead"
    primary-button-text="Create and assign"
    :loading="saving"
    secondary-button-text="Close"
    size="small"
    @update:model-value="emits('update:modelValue', false)"
    @confirm="submit"
  >
    <div class="flex flex-col gap-6 overflow-hidden">
      <p>
        You are up to create a Shadow lead. Please assign <br />
        a “Shadow-copy” lead to a new user.
      </p>
      <UiInputSelect
        v-model="user"
        :items="userItems"
        :loading="loading"
        name="user"
        group
        avatar
        placeholder="Choose user"
        class="mb-2"
        :error="useGetFieldErrors(v$, ['user'])"
      ></UiInputSelect>
    </div>
  </UiPopup>
</template>

<script setup lang="ts">
import { required, helpers } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import type { InputItem, Lead, User } from '@/types'
import { useUiStore } from '~/store/ui'

const emits = defineEmits<{
  (e: 'update:modelValue', value: boolean): void
  (e: 'input'): void
}>()

type Props = {
  modelValue: boolean
  leadId?: Lead['id']
  selectedLeads?: Lead[]
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: false,
  selectedLeads: undefined,
})

const user = ref<User['id'] | null>(null)

const userItems = ref<InputItem[]>([])

const loading = ref(false)

const getUsers = async () => {
  try {
    loading.value = true

    userItems.value = await useGetUsersItems(true)
  } finally {
    loading.value = false
  }
}

onNuxtReady(() => {
  getUsers()
})

const rules = computed(() => ({
  user: { required: helpers.withMessage('The user is required', required) },
}))

const v$ = useVuelidate(rules, {
  user,
})

const saving = ref(false)
const uiStore = useUiStore()

const submit = async () => {
  const isValid = await v$.value.$validate()
  if (!isValid) return

  saving.value = true
  let wrongResponse
  let successResponse

  if (props.selectedLeads?.length) {
    const leadIds = props.selectedLeads.map((l) => l.id)
    const { data, error } = await useCreateBulkShadowLeads(user.value!, leadIds)
    successResponse = data
    wrongResponse = error
  } else {
    const { data, error } = await useCreateShadowLead({
      assigneeId: user.value!,
      leadId: props.leadId,
    })
    successResponse = data
    wrongResponse = error
  }

  if (successResponse?.value?.data) {
    emits('update:modelValue', false)
    emits('input')

    if (!props.selectedLeads?.length)
      navigateTo(`/leads/${successResponse.value.data.id}`, { open: { target: '_blank' } })
  }

  if (wrongResponse?.value) {
    uiStore.showSnackBanner('', 'error')
  }

  saving.value = false
}
</script>

<style scoped></style>
